body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

.App {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: auto; /* Ensure scrollbars are always visible */
}

img {
  border: 2px solid black;
  box-shadow: 0px 0px 10px 0px #000;
}

.img-no-style {
  border: none !important;
  box-shadow: none !important;
}

//.App-logo {
//}
//
//@media (prefers-reduced-motion: no-preference) {
//  .App-logo {
//    animation: App-logo-spin infinite 20s linear;
//  }
//}
//
//.App-link {
//  color: #61dafb;
//}
//
//@keyframes App-logo-spin {
//  from {
//    transform: rotate(0deg);
//  }
//  to {
//    transform: rotate(360deg);
//  }
//}

$link: #11d3d5;

.wrap {
  p {
    a {
      transform: translate(-50%,-50%);
      position: relative;
      overflow: hidden;
      text-decoration: none;
      color: $link;
      &::after {
        content: "";
        background: rgba($link, 0.25);
        position: absolute;
        left: 12px;
        bottom: -6px;
        width: calc(100% - 8px);
        height: calc(100% - 8px);
        z-index: -1;
        transition: 0.35s cubic-bezier(0.25, 0.1, 0, 2.05);
      }
      &:hover:after {
        left: -5px;
        bottom: -5px;
        width: 110%;
        height: 150%;
      }
    }
  }
}

.link-effect {
    transform: translate(-50%,-50%);
    position: relative;
    overflow: hidden;
    text-decoration: none;
    color: $link;
    &::after {
      content: "";
      background: rgba($link, 0.25);
      position: absolute;
      left: 12px;
      bottom: -6px;
      width: calc(100% - 8px);
      height: calc(100% - 8px);
      z-index: -1;
      transition: 0.35s cubic-bezier(0.25, 0.1, 0, 2.05);
    }
    &:hover:after {
      left: -5px;
      bottom: -5px;
      width: 110%;
      height: 150%;
    }
  }

.plain-link {
  color: white;
  text-decoration: none;
  font-family: inherit;
  font-size: inherit;
  margin-left: 10px;
  margin-right: 10px;

  text-shadow:
          1px 1px 0 #0d120a,
          -1px -1px 0 #0d120a,
          1px -1px 0 #0d120a,
          -1px 1px 0 #0d120a;
}

.plain-link:hover {
  cursor: pointer;
  text-decoration: none;
}

$gradient-start: #1e3c72; // Dark blue
$gradient-end: #2a5298; // Lighter blue

$texture-url: url('https://www.transparenttextures.com/patterns/white-paper.png');

.background {
  height: 100vh; // Full height

  // Optional: Add a subtle overlay for better text readability
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.1); // White overlay with opacity
    z-index: 1;
  }

  // Adjust text color for readability
  .content {
    position: relative;
    z-index: 2; // Ensure content is above the overlay
    color: #ffffff; // White text for contrast
    padding: 20px; // Add some padding
  }
}
